





























































































































































































































































import CreditCardForm from '@/components/payments/CreditCardForm.vue';
import GetSonyPaymentToken from '@/components/payments/GetSonyPaymentToken.vue';
import SavedCardForm from '@/components/payments/SavedCardForm.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SubscriptionInfo } from '@/models/Subscription';
import { InputCard } from '@/models/InputCard';
import { EventItem, EventOptionItem } from '@/models/EventItem';
import { EventRepository } from '@/repositories/EventRepository';
import { ReqPostEventEntry } from '@/gen/api';
import { SubscriptionRepository } from '@/repositories/SubscriptionRepository';

Component.registerHooks(['beforeRouteEnter']);

@Component({
  components: {
    CreditCardForm,
    SavedCardForm,
    GetSonyPaymentToken
  }
})
export default class P0235 extends Vue {
  beforeRouteEnter(to: any, from: any, next: any) {
    if (from.name === 'event-detail') {
      next();
    } else {
      next('/events');
    }
  }

  @Prop({ required: true }) propEventItem!: string;
  @Prop({ required: true }) propRequestBody!: string;
  // 会員区分コード
  @Prop({ required: true })
  eventFeeClassCode!: string;

  // イベント情報
  eventItem = EventItem.new(JSON.parse(this.propEventItem) as EventItem);
  // リクエストボディ
  requestBody = JSON.parse(this.propRequestBody) as ReqPostEventEntry;

  buttonDisabled = false;
  buttonLoading = false;
  // 保存済みクレジットカード情報（表示用）
  subscriptionInfo = {} as SubscriptionInfo;
  // 入力されたクレジットカード情報
  inputCard = {} as InputCard;
  // エラーメッセージ
  errorMsg = '';
  // カードトークン
  token = '';
  // 決済ラジオボタンの初期値
  radioSelectedCreditCard = '';
  // カード情報入力部分を非活性化
  inputCardDisableFlag = false;
  // 支払合計金額
  payAmount(): number {
    return this.requestBody.event_options.reduce((sum, item) => {
      return sum + item.pay_amount;
    }, 0);
  }
  // 選択状態のイベント内選択肢
  selectedEventOptions(): EventOptionItem[] {
    return this.eventItem.eventOptions.filter(e => e.isSelected);
  }

  // イベントリポジトリ
  get eventRepo() {
    return new EventRepository();
  }

  // サブスクリポジトリ
  get subscriptionRepo() {
    return new SubscriptionRepository();
  }
  // TypeScriptで$refsを使用すると「TS2339: Property 'xxx' does not exist on type」というエラーが出るため、$refsを上書きする
  $refs!: {
    CreditCardForm: CreditCardForm;
    GetSonyPaymentToken: GetSonyPaymentToken;
  };

  async created() {
    await this.initialize();
  }

  async initialize() {
    if (!this.eventItem) {
      this.buttonDisabled = true;
      return;
    }
    if (this.eventFeeClassCode === this.$cls.EVENT_FEE_CLS.DISCOUNT.CD) {
      await this.getRegisterInfo();
    }
    this.setRadioValue();
  }

  // サブスク登録情報を取得する
  async getRegisterInfo() {
    await this.subscriptionRepo
      .getSubscriptionInfo()
      .then(res => {
        this.subscriptionInfo = res;
      })
      .catch((errCode: number) => {
        this.handleGetRegisterInfoErr(errCode);
      });
  }
  handleGetRegisterInfoErr(errCode: number) {
    switch (errCode) {
      case 42200:
        this.errorMsg = this.$msg.get('2000074', {
          errorCode: errCode
        });
        break;
      case 42206:
        this.errorMsg = this.$msg.get('2000074', {
          errorCode: errCode
        });
        break;
      case 50000:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      case 50001:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      default:
        this.errorMsg = this.$msg.get('2000078');
        break;
    }
  }

  setRadioValue() {
    // 保存済カード情報が「undefined」と「null」ではない場合、保存済カード
    this.radioSelectedCreditCard =
      this.subscriptionInfo != null &&
      this.subscriptionInfo.maskedCardNumber != null
        ? this.$cls.CREDIT_CARD_CLS.SAVED_CARD.CD
        : this.$cls.CREDIT_CARD_CLS.ANOTHER_CARD.CD;
    if (
      this.radioSelectedCreditCard === this.$cls.CREDIT_CARD_CLS.SAVED_CARD.CD
    ) {
      this.inputCardDisableFlag = true;
    }
  }

  // クレジットカード選択ラジオボタン選択
  selectCreditCardRadio(event: any) {
    // 保存済を選択した場合、カード情報入力部分を非活性化・入力値の削除・バリデーションエラーの削除を行う
    this.radioSelectedCreditCard = event.target.value;
    if (
      this.radioSelectedCreditCard === this.$cls.CREDIT_CARD_CLS.SAVED_CARD.CD
    ) {
      this.inputCard.number = '';
      this.inputCard.expYear = '';
      this.inputCard.expMonth = '';
      this.inputCard.securityCode = '';
      this.inputCardDisableFlag = true;
      this.$refs.CreditCardForm.$validator.reset();
    } else {
      this.inputCardDisableFlag = false;
    }
  }

  handleRegisteringMethod() {
    this.buttonLoading = true;
    this.errorMsg = '';
    // 保存済みカードで支払う場合
    if (
      this.radioSelectedCreditCard === this.$cls.CREDIT_CARD_CLS.SAVED_CARD.CD
    ) {
      this.register();
      // 新規カードで支払う場合
    } else {
      this.$refs.CreditCardForm.$validator.validateAll().then(valid => {
        if (valid) {
          this.getPaymentToken();
        } else {
          this.buttonLoading = false;
        }
      });
    }
  }
  async register() {
    this.buttonLoading = true;
    this.requestBody.pay_token = this.token;
    this.eventRepo
      .postEventEntry(this.requestBody)
      .then(() => {
        this.$router.push({
          name: 'register-event-complete',
          params: {
            propEventItem: JSON.stringify(this.eventItem),
            propRequestBody: JSON.stringify(this.requestBody),
            eventFeeClassCode: this.eventFeeClassCode
          }
        });
      })
      .catch((errCode: number) => {
        this.handleRegisterErr(errCode);
      })
      .finally(() => {
        this.buttonLoading = false;
      });
  }

  getPaymentToken() {
    this.$refs.GetSonyPaymentToken.getPaymentToken(
      this.inputCard.number,
      this.inputCard.expYear,
      this.inputCard.expMonth,
      this.inputCard.securityCode
    );
  }

  handleToken(token: string, err: any) {
    if (err) {
      // サーバエラーが発生しました。時間を空けて再度お試しください。
      this.errorMsg = this.$msg.get('2000011');
      this.buttonLoading = false;
    } else {
      this.token = token;
      this.register();
    }
  }

  // handleRegisterErrメソッドのcomplexityのエラーを回避できないため、disableする
  // eslint-disable-next-line complexity
  private handleRegisterErr(errCode: number) {
    switch (errCode) {
      case 40000:
        this.errorMsg = this.$msg.get('2000091');
        break;
      case 40001:
      case 40002:
      case 40003:
        this.errorMsg = this.$msg.get('2000092', {
          errorCode: errCode
        });
        break;
      case 40004:
        this.errorMsg = this.$msg.get('2000074', {
          errorCode: errCode
        });
        break;
      case 42200:
      case 42201:
      case 42202:
      case 42203:
        this.errorMsg = this.$msg.get('2000070', {
          errorCode: errCode
        });
        break;
      case 42204:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      case 42207:
        this.errorMsg = this.$msg.get('2000095');
        break;
      case 42208:
        this.errorMsg = this.$msg.get('2000096');
        break;
      case 42209:
        this.errorMsg = this.$msg.get('2000097');
        break;
      case 42211:
        this.errorMsg = this.$msg.get('2000098');
        break;
      case 42212:
        this.errorMsg = this.$msg.get('2000094');
        break;
      case 50000:
      case 50001:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      default:
        this.errorMsg = this.$msg.get('2000091');
        break;
    }
  }
}
